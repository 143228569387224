
//@flow
import React from "react";
import { graphql } from "gatsby";
// import styled from "styled-components";

import getGenericPage from "./generic/genericPage";
// import { color, breakpoint, headingFamily } from "../theme";


const RegisteredVideoContent = (props) => {
	const videoId = props.page.video;

	return <div>
		<h2>Testing Vimeo embed</h2>

		<iframe src={`https://player.vimeo.com/video/${videoId}`}
			width="400" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

	</div>;
};


export const pageQuery = graphql`
  query RegisteredVideoPageTemplate {
			markdownRemark(frontmatter: {templateKey: {eq: "registered-video-page" } }) {
			frontmatter {
				title
				tags
				description
				video
			}
	...RemoteBannerImage
  }
}`;

export default getGenericPage(RegisteredVideoContent, { showAd: true });
